@import 'custom-theme.scss';
@import 'variables';
@import 'material-custom-styles.scss';

@font-face {
  font-family: 'Roboto';
  src: url('/./assets/fonts/RobotoTTF/Roboto-Regular.ttf') format('truetype');
}

html {
  background: #E4EBEA url(/assets/images/background.png) no-repeat fixed center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  min-width: $application-min-width;
  height: 100vh;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: $font-size-general;
  color: mat-color($font-grey);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  color: mat-color($accent);
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

a {
  color: mat-color($font-grey);
  text-decoration: none;
}

p {
  margin: 0;
  color: mat-color($font-grey);
}

hr {
  border: 1px solid;
  color: mat-color($mat-font-palette, 300);
  margin: 0;
  width: 100%;
}

.confirm-snackbar {
  .mat-button {
    color: mat-color($success) !important;
  }
}

app-preview{
  height: 100%;
}
